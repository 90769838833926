.app {
  width: 100%;
  position: relative;
  display: grid;
  grid-template: 100% / 100%;
}

.page {
  grid-area: 1 / 1;
  min-height: 100lvh;
}

.skip {
  isolation: isolate;
  color: rgb(var(--rgbBackground));
  z-index: var(--zIndex4);

  &:focus {
    padding: var(--spaceS) var(--spaceM);
    position: fixed;
    top: var(--spaceM);
    left: var(--spaceM);
    text-decoration: none;
    font-weight: var(--fontWeightMedium);
    line-height: 1;
    box-shadow: 0 0 0 4px rgb(var(--rgbBackground)), 0 0 0 8px rgb(var(--rgbText));
  }

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background-color: rgb(var(--rgbPrimary));
    clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), calc(100% - 8px) 100%, 0 100%);
    z-index: -1;
  }
}
