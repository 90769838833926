:root {
  color-scheme: dark light;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

:where(html, body, #__next) {
  min-height: 100%;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

:where(input, textarea, select, button) {
  font: inherit;
  color: inherit;
  border: 0;
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  appearance: none;
}

:where(svg, img, picture, video, iframe, canvas) {
  display: block;
}

:any-link {
  text-decoration: none;
}

ul {
  padding: 0;
}
