@custom-media --mediaDesktop (max-width: 2080px);
@custom-media --mediaLaptop (max-width: 1680px);
@custom-media --mediaTablet (max-width: 1040px);
@custom-media --mediaMobile (max-width: 696px);
@custom-media --mediaMobileLS (max-height: 696px);
@custom-media --mediaMobileS (max-width: 400px);
@custom-media --mediaUseMotion (prefers-reduced-motion: no-preference);
@custom-media --mediaReduceMotion (prefers-reduced-motion: reduce);
@custom-media --mediaTouch (pointer: coarse);
@custom-media --mediaNoTouch (pointer: fine);

:root {
  --mobileNavOffset: calc(var(--spaceOuter) * 2 + var(--space2XL));
}

body {
  font-family: var(--fontStack);
  font-weight: var(--fontWeightRegular);
  background-color: rgb(var(--rgbBackground));
  transition: background var(--durationM) ease;
  color: var(--colorTextBody);
  width: 100vw;
  overflow-x: hidden;

  &:focus {
    outline: none;
  }
}

:where(code, pre) {
  font-family: var(--monoFontStack);
}

::selection {
  background: rgb(var(--rgbAccent));
  color: rgb(var(--rgbBlack));
}

:focus {
  outline: 4px solid rgb(var(--rgbText));
  outline-offset: 4px;
}

:focus:not(:focus-visible) {
  outline: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes reveal {
  0% {
    transform: scale3d(0, 1, 1);
    transform-origin: left;
  }

  50% {
    transform: scale3d(1, 1, 1);
    transform-origin: left;
  }

  51% {
    transform: scale3d(1, 1, 1);
    transform-origin: right;
  }

  100% {
    transform: scale3d(0, 1, 1);
    transform-origin: right;
  }
}
