button.toggle {
  --offset: var(--spaceXS);
  --inset: calc(var(--spaceOuter) - var(--offset));

  position: fixed;
  top: var(--inset);
  right: var(--inset);
  z-index: var(--zIndex4);
  display: none;

  @media (--mediaMobile), (--mediaMobileLS) {
    display: flex;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.icon {
  --iconSize: var(--spaceXL);

  position: absolute;
  transition-property: opacity, fill;
  transition-duration: var(--durationM);
  transition-timing-function: var(--bezierFastoutSlowin);
  opacity: 1;
  transform: rotate(0deg);
  fill: var(--colorTextBody);
  width: var(--iconSize);
  height: var(--iconSize);

  @media (--mediaUseMotion) {
    transition-delay: 0.1s;
    transition-property: opacity, transform, fill;
  }

  &[data-open='true'] {
    opacity: 0;

    @media (--mediaUseMotion) {
      transition-delay: 0s;
      transform: rotate(45deg);
    }
  }

  &[data-close='true'] {
    opacity: 0;

    @media (--mediaUseMotion) {
      transition-delay: 0s;
      transform: rotate(-45deg);
    }
  }

  &[data-open='true'],
  [data-close='true'] {
    opacity: 1;

    @media (--mediaUseMotion) {
      transition-delay: 0.1s;
      transform: rotate(0deg);
    }
  }
}
